import "../styles/Rechargement.css";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

function Rechargement() {
    const {setToken, getToken } = useAuth();
    const token = getToken();
    const navigate = useNavigate();	
	const pricingData = [
		{ id: 1, zp: 200, bonus: 0, price: 1.99 },
		{ id: 2, zp: 500, bonus: 0, price: 4.99 },
		{ id: 3, zp: 1000, bonus: 100, price: 9.99 },
		{ id: 4, zp: 2000, bonus: 250, price: 19.99 },
		{ id: 5, zp: 3500, bonus: 425, price: 34.99 },
		{ id: 6, zp: 5000, bonus: 650, price: 49.99 },
	];
	// const pricingData = [
         // { id: 1, zp: 200, bonus: 0+30, price: 1.99 },
         // { id: 2, zp: 500, bonus: 0+75, price: 4.99 },
         // { id: 3, zp: 1000, bonus: 100+165, price: 9.99 },
         // { id: 4, zp: 2000, bonus: 250+340, price: 19.99 },
         // { id: 5, zp: 3500, bonus: 425+590, price: 34.99 },
         // { id: 6, zp: 5000, bonus: 650+850, price: 49.99 },
     // ];
    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelect = (option) => {
        setSelectedOption(option);
    };

    const handleProceedToPayment = () => {
        if (!selectedOption) {
            alert('Veuillez sélectionner une option de rechargement');
            return;
        }
    };

    return (
        <div className="rechargement_box">
            <div className="rechargement_head">
                <h2>Rechargement</h2>
                <hr className="sep" />
            </div>
			<div className="rechargement_input">
				<div className="pricing-container">
                    {pricingData.map(option => (
                    <div
                        key={option.id}
                        className={`pricing-card ${selectedOption && selectedOption.id === option.id ? 'selected' : ''}`}
                        onClick={() => handleSelect(option)}
                    >
                        <div className="pricing-rp">
                            {option.zp+option.bonus} ZC
                            {option.bonus > 0 && <span> ({option.zp} + {option.bonus} bonus)</span>}
                        </div>
                        <div className="pricing-price">{option.price} €</div>
                    </div>
                    ))}
				</div>
			</div>
            {selectedOption && (
                <div className="payment-button-container">
                    <button onClick={handleProceedToPayment}></button>
                    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "EUR" }}>
                        <PayPalButtons
                            createOrder={async (data, actions) => {
                                try {
                                    if (!token) return;
                                    const response = await fetch(process.env.REACT_APP_BACKEND_URL +'/create-payment', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({ pricingId: selectedOption.id,token:getToken() })
                                    });
                                    if (!response.ok) {
                                        const error = await response.text();
                                        throw new Error(`HTTP error! Status: ${response.status}, Message: ${error}`);
                                    }
                                    const { id } = await response.json();
                                    return id;
                                } catch (error) {
                                    console.error('Error creating order:', error);
                                    throw error;
                                }
                            }}
                            onApprove={async (data, actions) => {
                                return fetch(process.env.REACT_APP_BACKEND_URL +`/capture-payment/${data.orderID}`, {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${getToken()}` 
                                },
                                body: JSON.stringify({ pricingId: selectedOption.id }),
                                })
                                .then(response => response.json())
                                .then(details => {
                                  navigate(`/success/${data.orderID}`);  
                                })
                                .catch(error => {
                                    console.error('Error capturing order:', error);
                                    navigate('/cancel');
                                });
                            }}
                            onError={(err) => {
                                console.error('Payment error:', err);
                                navigate('/cancel');
                            }}
                            fundingSource="paypal"
                        />
                    </PayPalScriptProvider>
                </div>
            )}

            <p>Pour les rechargements autres que par PayPal,<br /> veuillez contacter un administrateur.</p>
            <hr className="sep" />
            <p className="small">Des événements happy-hour peuvent arriver à n'importe quel moment, rejoignez-nous sur Discord pour etre au courant de tout !</p>
            {/*<p className="small">Un événement happy-hour est en cours jusqu'à Dimanche 03/11 à 23h59 ! Vous recevrez +15% de ZC !</p> */}
            {/*<p className="small">Des événements happy-hour peuvent arriver à n'importe quel moment, rejoignez-nous sur Discord pour être au courant de tout !</p>*/}
        </div>
    );
}

export default Rechargement;
