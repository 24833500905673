import "../styles/Vote.css";
import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Error from './Error';
function Vote() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const {userInfos,getToken} = useAuth();

    const handleVote = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/vote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:
             JSON.stringify({ 
                token:getToken()
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setMessage({text:data.message,color:data.success ? 'green' : 'red'});
                setTimeout(() => {
                    setMessage({text:"",color:""});
                }, 3000);

            } else {
                setMessage({text:data.message,color:data.success ? 'green' : 'red'});
                setTimeout(() => {
                    setMessage({text:"",color:""});
                }, 10000);
            }
        })
    }

    return (
        <div className="vote">
            <div className="vote-head">
                <h2>Voter pour Zoria</h2>
                <hr className='sep'></hr>
            </div>
            <div className="vote-pannel">
                <a href={process.env.REACT_APP_VOTE_URL} target="_blank" rel="noopener noreferrer" className="vote-link">Cliquez ici pour voter !</a>
                <div className="vote_btn">
                    <input className="vote_btn" type="submit" id="submit" value="Valider son vote" onClick={handleVote}/>
                </div>
                <div className='message_error'>
                        <Error msg={{text:message.text,color:message.color}} />
                    </div>
            </div>
        </div>
    );
}
export default Vote