import "../styles/GestionBan.css";
import { Link } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import Error from './Error';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function GestionBan() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();
    const { setToken, getToken,userInfos } = useAuth();
    const [isLoaded, setIsLoaded] = useState(false);
	const token = getToken();
    const [pseudo, setPseudo] = useState('');
    const [ban_value, setBanCategorie] = useState('');
    const [ban_reason, setBanReason] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [banList,setBanList] = useState([]);


    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token,navigate]);

    useEffect(() => {
        if (token) {
            fetch(process.env.REACT_APP_BACKEND_URL + '/isa', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token })
            })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    navigate('/');
                }
                setIsLoaded(true);
            });
        }
    }, [token,navigate]);

    const handleBan = (pseudo) => async (e) => {
        e.preventDefault();

        if (!pseudo) {
            setMessage({text:'Veuillez saisir un pseudo',color:'red'});
            return;
        }

        if (!ban_value) {
            setMessage({text:'Veuillez choisir une catégorie de bannissement',color:'red'});
            return;
        }

        if (!ban_reason) {
            setMessage({text:'Veuillez saisir une raison de bannissement',color:'red'});
            return;
        }
        

        fetch(process.env.REACT_APP_BACKEND_URL + '/ban', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pseudo: pseudo,
                type: ban_value,
                date: startDate,
                reason: ban_reason,
                token : token
            })
        })
        .then(response => response.json())
        .then(data => {
            setMessage({text: data.message, color: data.success ? 'green' : 'red'});
        
            setTimeout(() => {
                setMessage({text: '', color: ''});
                setPseudo('');
                setBanCategorie('');
                setBanReason('');
            }, 3000);
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });

    }

    const handleUnban = (pseudo) => async (e) => {
        e.preventDefault();

        if (!pseudo) {
            setMessage({text:'Veuillez saisir un pseudo',color:'red'});
            return;
        }

        fetch(process.env.REACT_APP_BACKEND_URL + '/unban', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                pseudo: pseudo
            })
        })
        .then(response => response.json())
        .then(data => {
            setMessage({text: data.message, color: data.success ? 'green' : 'red'});
        
            setTimeout(() => {
                setMessage({text: '', color: ''});
                setPseudo('');
                setBanCategorie('');
                setBanReason('');
                setBanList([]);
            }, 3000);
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });
    }

    const handleBanList = () => async (e) => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/banlist', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token : token
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log(data.banned);
                setBanList(data.banned);
            }
            setTimeout(() => {
                setBanList([]);
            },1000*60*5);
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });
    }

    return (
        <>
        
        <div className="ban_admin">
            <div className="ban_admin_head">
                <h2>Gestion des bannissements</h2>
                <hr className="sep"/>
            </div>
            {isLoaded ? (
                <>
                <div className="ban_admin_pannel">
                    <div className='message_error'>
                        <Error msg={{text:message.text,color:message.color}} />
                    </div>
                    <input type="text" placeholder="Pseudo" className="pseudo_ban" value={pseudo} onChange={(e) => setPseudo(e.target.value)}/>
                    <select value={ban_value} onChange={(e) => setBanCategorie(e.target.value)} required="true" className="select_bans">
                        <option value="">Choisir une catégorie</option>
                        <option value="1">Ban IP avec durée</option>
                        <option value="2">Ban avec durée</option>
                        <option value="3">Ban IP définitif</option>
                        <option value="4">Ban définitif</option>
                    </select>
                    <input type="text" placeholder="Raison du bannissement" className="ban_reason" value={ban_reason} onChange={(e) => setBanReason(e.target.value)}/>
                    <Calendar onChange={(date) => setStartDate(date)} value={startDate} />
                    <hr className="sep"/>
                    <button className="ban_button" onClick={handleBan(pseudo)}>Bannir</button>
                    <button className="deban_button" onClick={handleUnban(pseudo)}>Débannir</button>
                    <button className="banlist_button" onClick={handleBanList()}>Liste des bannis</button>
                    <hr className="sep"/>
                    <div className="banlist">
                        <ul>
                            {banList && banList.map((ban) => (
                                <li key={ban.login}>{ban.bestPlayer} - {ban.reason} - {new Date(ban.duree).toLocaleString('fr-FR',{year: 'numeric', month: '2-digit', day: '2-digit',hour: '2-digit', minute: '2-digit', second: '2-digit',hour12: false}).replace(/T/, ' ').replace(/\u200E/g, '').replace(/,/g, '')} - <a onClick={handleUnban(ban.bestPlayer)}>Débannir</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
        </>
    );
}
export default GestionBan;