import React, {useState} from 'react';
import '../styles/ClassementJoueur.css';
import chunjo_icon from "../assets/left/rank/chunjo_icon.png";
import jinno_icon from "../assets/left/rank/jinno_icon.png";
import shinsoo_icon from "../assets/left/rank/shinsoo_icon.png";
import discord_img from "../assets/left/discord_img.png";
import { Link } from 'react-router-dom';
import Error from './Error';
import {useNavigate} from 'react-router-dom';
import { useAuth } from './AuthContext';




function ClassementJoueur() {
const { leftRanking} = useAuth();
    
return (
	<>
		<div className="left_player_ranking">
			<div className="left_player_ranking_he">
				<h2>Classement Joueurs</h2>
			</div>
			<table>
				<thead>
					<tr>
						<th style={{ width: '38px' }}>#</th>
						<th style={{ width: '119px' }}>Nom</th>
						<th style={{ width: '87px' }}>Empire</th>
						<th style={{ width: '120px' }}>Niveau</th>
					</tr>
				</thead>
				<tbody>
					{leftRanking.map(player => (
						<tr className="rank_catplayer" key={player.rank}>
							<td className="left_player_rank_no">{player.rank}.</td>
							<td className="left_player_rank_name">{player.name}</td>
							<td className="left_player_rank_emp">
								{player.empire === 1 ? (
									<img src={shinsoo_icon} alt="emp_flag" />
								) : player.empire === 2 ? (
									<img src={chunjo_icon} alt="emp_flag" />
								) : (
									<img src={jinno_icon} alt="emp_flag" />
								)}
							</td>
							<td className="left_player_rank_level">{player.level}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
		<div className="show_all_rank_left">
			<Link to='ranking/player'>Afficher le classement complet</Link>
		</div>
	</>
);
}

export default ClassementJoueur;
