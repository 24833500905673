import React from 'react';
import "../styles/SuccessPage.css";
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
function SuccessPage() {
  const { orderID } = useParams(); // Récupère l'ID de la commande à partir des paramètres d'URL
  const navigate = useNavigate();
  const [zc, setZc] = useState(0); // Initialise le nombre de ZoriaCoins à 0
  useEffect(() => {
    if (!orderID) {
      navigate('/');
    }
    else
    {
      try {
        fetch(process.env.REACT_APP_BACKEND_URL + '/success-amount', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orderID }) // Envoie l'ID de la commande pour vérification
        })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              setZc(data.zc); // Met à jour le nombre de ZoriaCoins
            }
          })
        }
        catch (error) {
          console.error('Error verifying payment:', error);
          navigate('/cancel'); // Redirige vers /cancel en cas d'erreur
      }
    }
  }, [orderID,navigate]);


  return (
    <div className="success_box">
      <div className="success_head">
        <h2>Paiement réussi</h2>
        <hr className="sep" />
      </div>
      <div className="success_input">
        <p>Votre paiement a été effectué avec succès.</p>
        <p>Vous avez recu : {zc} ZoriaCoins</p>
		<p>Merci pour votre achat ! Veuillez noter que les ZoriaCoins achetés sont considérés comme livrés immédiatement après paiement et ne sont ni remboursables, ni échangeables conformément à notre politique. En procédant à cet achat, vous acceptez ces conditions.</p>
        <p>Id de transaction : {orderID}</p> {/* Affiche l'ID de transaction */}
        <p>Cliquez <a href="/account">ici</a> pour retourner sur votre compte.</p>
      </div>
    </div>
  );
}

export default SuccessPage;
