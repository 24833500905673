import "../styles/ResetPassword.css";
import { Link,useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Error from './Error';


function ResetPassword() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const [new_password, setNewPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    let { resetKey } = useParams();
    
    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/verifyResetKey', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                resetKey: resetKey
            })
        })
        .then(response => response.json())
        .then(data => {
            if (!data.success) {
                navigate('/');
            }
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });
    }, []);


    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!new_password) {
            setMessage({text:'Veuillez saisir un nouveau mot de passe',color:'red'});
            return;
        }
        if (!confirm_password) {
            setMessage({text:'Veuillez confirmer le nouveau mot de passe',color:'red'});
            return;
        }
        if (new_password !== confirm_password) {
            setMessage({text:'Les mots de passe ne correspondent pas',color:'red'});
            return;
        }
        if (new_password.length < 4) {
            setMessage({text:'Le mot de passe doit contenir au moins 4 caractères',color:'red'});
            return;
        }

        fetch(process.env.REACT_APP_BACKEND_URL + '/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                resetKey: resetKey,
                new_password: new_password,
                confirm_password: confirm_password
            })
        })
        .then(response => response.json())
        .then(data => {
            setMessage({text: data.message, color: data.success ? 'green' : 'red'});
        
            setTimeout(() => {
                setMessage({text: '', color: ''});
                setNewPassword('');
                setConfirmPassword('');
            }, 3000);
    
            if (data.success) {
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            }
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });

    }


    return (
    <div className="reset_pwd">
        <div className="reset_pwd_head">
            <h2>Réinitialiser votre mot de passe</h2>
            <hr className="sep"/>
        </div>
        
        <div className="reset_pwd_pannel">
            <form onSubmit={handleResetPassword}>
            <input type="password" id="name" className="new_password" placeholder="Votre nouveau mot de passe" value={new_password} onChange={(e) => setNewPassword(e.target.value)}/>
            <input type="password" id="name" className="new_password_confirm" placeholder="Veuillez répeter le nouveau mot de passe" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)}/>
                <button type="submit" class="change_password">Changer le mot de passe</button>
            </form>
        </div>

        <div className='message_error'>
            <Error msg={{text:message.text,color:message.color}} />
        </div>

    </div>
    );
}
export default ResetPassword