import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setTokenState] = useState('');
  const [userInfos, setUserInfos] = useState({});
  const [siteInfos, setSiteInfos] = useState({});
  const [leftRanking, setLeftRanking] = useState([]);
  const [rightRanking, setRightRanking] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [canRegister, setCanRegister] = useState(0);
  const [isBanned, setIsBanned] = useState({});


  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/isa', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: getToken() })
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    });
}, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/canRegister', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
        if(data.success) {
          setCanRegister(data.canRegister);
        }
        else {
          console.error(data.message);
        }
    })
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/site-infos', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    }) 
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setSiteInfos(data.stats);
        } else {
            console.error(data.message);
        }
    });
}, []);;



  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/left-ranking', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    }) 
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setLeftRanking(data.ranking);
        } else {
            console.error(data.message);
        }
    });
}, []);;


  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/right-ranking', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    }) 
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setRightRanking(data.ranking);
        } else {
            console.error(data.message);
        }
    });
}, []);;


  useEffect(() => {
    const token = getToken();
    if (token) {
      setTokenState(token);
      fetch(process.env.REACT_APP_BACKEND_URL + '/account', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            token:getToken(), 
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setIsBanned(data.infos.isBanned);
            setUserInfos(data.infos);
        } else {
            console.error(data.message);
        }
    });
    }
  }, []);


  const fetchUserInfos = () => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/account', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            token:getToken(), 
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            setIsBanned(data.infos.isBanned);
            setUserInfos(data.infos);
        } else {
            console.error(data.message);
        }
    });
  }

  const setToken = (userToken) => {
    setTokenState(userToken);
    if (userToken !== '') {
      localStorage.setItem('token', userToken);
    } else {
      localStorage.removeItem('token');
    }
  };

  const getToken = () => {
    return localStorage.getItem('token') || '';
  };

  return (
    <AuthContext.Provider value={{ token, fetchUserInfos,setToken, getToken,setUserInfos,setIsBanned,userInfos, siteInfos, leftRanking, rightRanking, isAdmin, canRegister, isBanned}}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
