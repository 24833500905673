import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './AuthContext';
import {useNavigate} from 'react-router-dom';

function Header() {
  const { token, setToken, getToken } = useAuth();

  return (
    <>
      <div className="navbar">
        <Link to="/">Accueil</Link>
        {!token ? (
          <Link to="/inscription">Inscription</Link>
        ) :
        (
          <Link to="/account">Mon compte</Link>
        )
        }
        <div className="dropdown">
          <button className="dropbtn">
            Classements
          </button>
          <div className="dropdown-content">
            <Link to="/ranking/player">Classement joueurs</Link>
            <Link to="/ranking/guild">Classement guildes</Link>
            <Link to="/ranking/vote">Classement votes</Link>
          </div>
        </div>

        <Link to="https://discord.gg/BRyCdfKtuD">Discord</Link>
        <Link to="/download">Télécharger</Link>
      </div>
      <div className="headerpact">
        <section className="items_header">
        </section>
      </div>
    </>
  );
}

export default Header;
