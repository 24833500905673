import "../styles/Admin.css";
import { Link } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import Error from './Error';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';


function Admin() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const { setToken, getToken,userInfos } = useAuth();
	const token = getToken();

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token,navigate]);

    useEffect(() => {
        if (token) {
            fetch(process.env.REACT_APP_BACKEND_URL + '/isa', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token })
            })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    navigate('/');
                }
                setIsLoaded(true);
            });
        }
    }, [token,navigate]);

    return (
        <div className="admin">
            <div className="admin_head">
                <h2>Admin Pannel</h2>
                <hr className="sep"/>
            </div>
            {isLoaded ? (
                <div className="admin_pannel">
                    <div className="admin_pannel_content">
                    <Link to="/admin/news" className="admin_link">Gestion des news</Link>
                    <Link to="/admin/ban" className="admin_link">Gestion des bannissement</Link>
                    <Link to="/admin/paypal" className="admin_link">Historique paypal</Link>
                    <Link to="/admin/itemshop" className="admin_link">Gestion de l'itemshop</Link>
                    <Link to="/admin/createitem" className="admin_link">Créer un item</Link>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}export default Admin