import "../styles/GestionNews.css";
import { Link } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import Error from './Error';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';


function GestionNews() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();
    const { setToken, getToken,userInfos } = useAuth();
    const [news_categorie, setNewsCategorie] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
	const token = getToken();
    const [auteur, setAuteur] = useState('');
    const [news, setNews] = useState('');

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token,navigate]);

    useEffect(() => {
        if (token) {
            fetch(process.env.REACT_APP_BACKEND_URL + '/isa', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token })
            })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    navigate('/');
                }
                setIsLoaded(true);
            });
        }
    }, [token,navigate]);

    return (
        <>
        
        <div className="news_admin">
            <div className="news_admin_head">
                <h2>Gestion des news</h2>
                <hr className="sep"/>
            </div>
            {isLoaded ? (
                <><div className="news_admin_pannel">
                    <div className='message_error'>
                        <Error msg={{text:message.text,color:message.color}} />
                    </div>
                    <input type="text" placeholder="Auteur" className="auteur_news" value={auteur} onChange={e => setAuteur(e.target.value)} />
                    <textarea placeholder="News a ajouter" className="news_add" value={news} onChange={e => setNews(e.target.value)} />
                    <select value={news_categorie} onChange={(e) => setNewsCategorie(e.target.value)} required="true" className="select_news">
                        <option value="">Choisir une catégorie</option>
                        <option value="1">Annonce</option>
                        <option value="2">Mise à jour</option>
                    </select>
                    <hr className="sep"/>
                    <button className="add_news" onClick={() => {
                        if (!auteur || !news || !news_categorie) {
                            setMessage({ text: 'Veuillez remplir tous les champs.', color: 'red' });
                            setTimeout(() => {
                                setMessage({ text: '', color: '' });
                            }, 3000);
                            return;
                        }

                        fetch(process.env.REACT_APP_BACKEND_URL + '/add-news', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ token: token, auteur: auteur, news: news, news_categorie: news_categorie})
                        })
                            .then(response => response.json())
                            .then(data => {
                                if (data.success) {
                                    setMessage({ text: data.message, color: 'green' });
                                } else {
                                    setMessage({ text: data.message, color: 'red' });
                                }
                            });
                    } }>Ajouter</button>
                </div>
                
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
        </>
    );
}
export default GestionNews;