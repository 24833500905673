import React, {useState, useEffect} from 'react';
import '../styles/PlayerRanking.css';
import chunjo_icon from "../assets/left/rank/chunjo_icon.png";
import jinno_icon from "../assets/left/rank/jinno_icon.png";
import shinsoo_icon from "../assets/left/rank/shinsoo_icon.png";
import { Link } from 'react-router-dom';
import {useParams} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './AuthContext';
import {useNavigate} from 'react-router-dom';


function GuildRanking() {
    const [result, setResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const resultPerPage = 10;
    useEffect(() => {
        setIsLoading(true);
        fetch(process.env.REACT_APP_BACKEND_URL + '/guild-ranking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }) 
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setResult(data.guildranking);
            } else {
                console.error(data.message);
            }
        });
        setIsLoading(false);
    }, []);

    const totalPages = Math.ceil(result.length / resultPerPage);
    const topTenPlayers = result.slice(0, resultPerPage);
    const [filteredPlayers, setFilteredPlayers] = useState(topTenPlayers);
    const [searchTerm, setSearchTerm] = useState('');
         
    useEffect(() => {
        if (!isLoading) {
          const start = (currentPage - 1) * resultPerPage;
          const end = start + resultPerPage;
          const filtered = searchTerm
            ? result.filter(player => player.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : result;
          setFilteredPlayers(filtered.slice(start, end));
        }
      }, [searchTerm, currentPage, result, isLoading]);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
        setCurrentPage(1);
    };
    
    const nextPage = () => {
        const nextPage = Math.min(currentPage + 1, totalPages);
        setCurrentPage(nextPage);
    };
    
    const prevPage = () => {
        const prevPage = Math.max(currentPage - 1, 1);
        setCurrentPage(prevPage);
    };



return (
    <div className='ranking'>
        <div className='ranking_head'>
            <h2>Classements</h2>
            <hr className='sep'></hr>
        </div>

        <div className="ranking_buttons">
            <Link to='/ranking/player' className="player_button">Joueurs</Link>
            <Link to='/ranking/guild' className="guild_button">Guildes</Link>
            <Link to='/ranking/vote' className="vote_button">Votes</Link>
        </div>

        <div className="ranking_search_bar">
            <input
                type="text"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
        </div>

        <div className="ranking_pannel">
			<div style={{ height: "400px" }}>
				<table className="full-ranking">
					<thead>
						<tr>
							<th className="player_rank_no" style={{ width: "38px" }}>#</th>
							<th className="player_rank_name" style={{ width: "119px" }}>Nom</th>
							<th className="player_rank_race" style={{ width: "100px" }}>Chef</th>
							{/*<th className="player_rank_guild" style={{ width: "119px" }}>Guilde</th> */}
							<th className="player_rank_emp" style={{ width: "87px" }}>Empire</th>
							<th className="player_rank_level" style={{ width: "40px" }}>Niveau</th>
							<th className="player_rank_exp" style={{ width: "120px" }}>Points</th>
						</tr>
					</thead>
					<tbody>
						{filteredPlayers.map((guild) => (
							<tr className="rank_catplayer" key={guild.rank}>
								<td className="player_rank_no">{guild.rank}.</td>
								<td className="player_rank_name">{guild.name}</td>
								<td className="player_rank_race">{guild.chef}</td>
								{/*<td className="player_rank_guild">{player.guild}</td> */}
								<td className="player_rank_emp">
									{guild.empire === 1 ? (
										<img src={shinsoo_icon} alt="emp_flag" />
									) : guild.empire === 2 ? (
										<img src={chunjo_icon} alt="emp_flag" />
									) : (
										<img src={jinno_icon} alt="emp_flag" />
									)}
								</td>
								<td className="player_rank_level">{guild.level}</td>
								<td className="player_rank_exp">{guild.ladder_point}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
            <div className='pagination'>
                <FontAwesomeIcon icon={faArrowLeft} onClick={prevPage} />
                <input type='number' min="1" max="5" className='pageInput' value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))} />
                <FontAwesomeIcon icon={faArrowRight} onClick={nextPage} />
            </div>
        </div>
    </div>
);
}

export default GuildRanking;
