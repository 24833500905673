import React from 'react';
import "../styles/CancelPage.css";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';


function CancelPage() {
  return (
    <div className="cancel_box">
      <div className="cancel_head">
        <h2>Annulation</h2>
        <hr className="sep" />
      </div>
      <div className="cancel_input">
        <p>Votre paiement a été annulé.</p>
        <p>Si vous avez des questions, veuillez contacter le support.</p>
      </div>
    </div>
  );
}

export default CancelPage;