import React from 'react';
import '../styles/RightSide.css';
import img_vote from "../assets/right/img_vote.png";
import img_dl from "../assets/right/img_dl.png";
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import ClassementGuilde from './ClassementGuilde';

function RightSide() {
    const {siteInfos} = useAuth();

  return (
    <>
    <div className='grid-container'>
        <div className="right-content grid-item">
            <div className="stats">
                <div className="stats_title">
                    <h3>Statistiques du serveur</h3>
                </div>
                
                <div className="stats_content">
                    <div className="div1">Comptes</div>
                    <div className="div2">Personnages</div>
                    <div className="div3">Guildes</div>
                    <div className="div4">En ligne </div>
                    <div className="div5">En ligne /24h</div>
                    <div className="div6" style={{textAlign :'right'}}>{siteInfos.nbAccounts}</div>
                    <div className="div7" style={{textAlign :'right'}}>{siteInfos.nbCharacters}</div>
                    <div className="div8" style={{textAlign :'right'}}>{siteInfos.nbGuilds}</div>
                    <div className="div9" style={{textAlign :'right', color: '#95da3b'}}>{siteInfos.online}</div>
                    <div className="div10" style={{textAlign :'right'}}>{siteInfos.online24}</div>
                </div>


                <div className='links-image'>
                    <div className="vote-img">
                        <Link to='/vote'><img src={img_vote} alt="vote"/></Link>
                    </div>

                    <div className="play-img">
                        <Link to ='/download'><img src={img_dl} alt="Télécharger"/></Link>
                    </div>
                </div>
            </div>
            <ClassementGuilde />
        </div>
    </div>
    </>
  );
}
export default RightSide;