import "../styles/LostUser.css";
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Error from './Error';
import {useNavigate} from 'react-router-dom';


function LostUser() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const [email , setEmail] = useState('');
    const navigate = useNavigate();

    const handleLostUser = async (e) => {
        e.preventDefault();

        if (!email) {
            setMessage({text:'Veuillez saisir votre adresse mail.',color:'red'});
            return;
        }

        fetch(process.env.REACT_APP_BACKEND_URL + '/lostuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        })

        .then(response => response.json())
        .then(data => {
            setMessage({text: data.message, color: data.success ? 'green' : 'red'});
        
            setTimeout(() => {
                setMessage({text: '', color: ''});
                setEmail('');
            }, 3000);

            if (data.success) {
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            }
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });
    }

    return (
    <div className="lostuser">
        <div className="lostuser_head">
            <h2>Nom de compte oublié ?</h2>
            <hr className="sep"/>
        </div>
        
        <div className="lostuser_pannel">
            <form onSubmit={handleLostUser}>
                <input type="text" placeholder="L'adresse mail de votre compte ?" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <button type="submit" class="change_password">Changer le mot de passe</button>
            </form>
        </div>

        <div className='message_error'>
            <Error msg={{text:message.text,color:message.color}} />
        </div>

    </div>
    );
}
export default LostUser;