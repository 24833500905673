import "../styles/Login.css";
import { Link } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import Error from './Error';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';


function Login() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();
    const [username , setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { token, setToken, getToken } = useAuth();


    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token,navigate]);


	const handleLogin = async (e) => {
		e.preventDefault();

		if (!username || !password) {
			setMessage({text:"Veuillez remplir tous les champs.",color:"red"});
			setTimeout(() => {
				setMessage({text:"",color:""});
				setUsername('');
				setPassword('');
			}, 3000);
			
			return;
		}

		fetch(process.env.REACT_APP_BACKEND_URL + '/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ 
				username:username, 
				password:password
			})
		})
		.then(response => response.json())
		.then(data => {
			setMessage({text:data.message,color:data.success ? 'green' : 'red'});
			setTimeout(() => {
				setMessage({text:"",color:""});
			}, 3000);
			
			setPassword('');

			if (data.success) {
				setTimeout(() => {
					setToken(data.token);
					setUsername('');
                    navigate('/');
				}, 3000);
			}
		})
		.catch(error => {
			console.error('Error:', error);
		});
	}

    return (
        <div className="log">
            <div className="log_head">
                <h2>Se connecter</h2>
                <hr className="sep"/>
            </div>

            <div className='message_error'>
                <Error msg={{text:message.text,color:message.color}} />
            </div>

            <form onSubmit={handleLogin}>
					<div className="log_panel_content">
						<div className='username_log'>
							<input type="text" id="username_log" name="_username_log" placeholder="Nom de compte" value={username} onChange={(e) => setUsername(e.target.value)}/>
						</div>
						<div className='password_log'>
							<input type="password" id="password_log" name="_password_log" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
						</div>
						<div className='login_log'>
							<input className="subtitle_panel" type="submit" id='submit' value='Connexion'/>
						</div>
					</div>
			</form>
            <hr className="sep"/>
        </div>
    );
}export default Login
