import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Header';
import Accueil from './Accueil';
import LeftSide from './LeftSide';
import '../styles/App.css';
import RightSide from './RightSide';
import Inscription from './Inscription';
import Telechargement from './Telechargement';
import Rechargement from './Rechargement';
import LostPwd from './LostPwd';
import LostUser from './LostUser';
import Compte from './Compte';
import Vote from './Vote';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PlayerRanking from './PlayerRanking';
import Footer from './Footer';
import NotFoundRedirect from './NotFoundRedirect';
import { AuthProvider } from './AuthContext';
import Login from './Login';
import TokenVerifier from './TokenVerifier';
import ResetPassword from './ResetPassword';
import GuildRanking from './GuildRanking';
import Admin from './Admin';
import GestionNews from './GestionNews';
import VoteRanking from './VoteRanking';
import GestionBan from './GestionBan';
import CreateItem from './CreateItem';
import SuccessPage from './SuccessPage';
import CancelPage from './CancelPage';
import Discord from './Discord';

function App() {
	return (
		<Router>
			<AuthProvider>
				<GoogleReCaptchaProvider reCaptchaKey="6LdWcIIpAAAAAH9lyO2JBuIzewQO2Owf1OqWXWoK">
				<TokenVerifier>
					<Header />
					<div className='grid-container'>
						<LeftSide/>
						<div className='middle-content'>
							<Routes>
								<Route exact path="/" element={<Accueil/>} />
								<Route exact path="/inscription" element={<Inscription />} />
								<Route exact path="/download" element={<Telechargement/>} />
								<Route exact path="/rechargement" element={<Rechargement/>} />
								<Route exact path="/lostpwd" element={<LostPwd/>} />
								<Route exact path="/lostuser" element={<LostUser/>} />
								<Route exact path="/account" element={<Compte/>} />
								<Route exact path="/vote" element={<Vote/>} />
								<Route exact path="/ranking/player" element={<PlayerRanking/>} />
								<Route exact path="/ranking/guild" element={<GuildRanking/>} />
								<Route exact path="/ranking/vote" element={<VoteRanking/>} />
								<Route exact path="/login" element={<Login/>}/>
								<Route exact path="/reset-password/:resetKey" element={<ResetPassword/>}/>
								<Route path="/admin" element={<Admin />} />
								<Route path="/admin/news" element={<GestionNews />} />
								<Route path="/admin/ban" element={<GestionBan />} />
								<Route path="/admin/createitem" element={<CreateItem />} />
								<Route path="/success/:orderID" element={<SuccessPage/>} />
								<Route path="/cancel" element={<CancelPage/>} />
								<Route path="/discord" element={<Discord/>} />
								<Route path="*" element={<NotFoundRedirect />} />
							</Routes>
						</div>
						<RightSide/>
					</div>
					<Footer/>
				</TokenVerifier>
				</GoogleReCaptchaProvider>
			</AuthProvider>
		</Router>
	);
}

export default App;
