import React, {useState} from 'react';
import '../styles/LeftSide.css';
import chunjo_icon from "../assets/left/rank/chunjo_icon.png";
import jinno_icon from "../assets/left/rank/jinno_icon.png";
import shinsoo_icon from "../assets/left/rank/shinsoo_icon.png";
import discord_img from "../assets/left/discord_img.png";
import { Link } from 'react-router-dom';
import Error from './Error';
import {useNavigate} from 'react-router-dom';
import { useAuth } from './AuthContext';
import ClassementJoueur from './ClassementJoueur';




function LeftSide() {
	const { token, setToken, getToken, userInfos, setUserInfos,setIsBanned} = useAuth();
    const navigate = useNavigate();
    

    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [keepLogin, setKeepLogin] = useState(false);
    const [message, setMessage] = useState({ text: '', color: '' });

	const handleLogin = async (e) => {
		e.preventDefault();

		if (!username || !password) {
			setMessage({text:"Veuillez remplir tous les champs.",color:"red"});
			setTimeout(() => {
				setMessage({text:"",color:""});
				setUsername('');
				setPassword('');
			}, 3000);
			
			return;
		}

		fetch(process.env.REACT_APP_BACKEND_URL + '/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ 
				username:username, 
				password:password, 
				keepLogin:keepLogin
			})
		})
		.then(response => response.json())
		.then(data => {
			setMessage({text:data.message,color:data.success ? 'green' : 'red'});
			setTimeout(() => {
				setMessage({text:"",color:""});
			}, 3000);
			
			setPassword('');

			if (data.success) {
				setToken(data.token);
				setUserInfos(data.infos);
				setIsBanned(data.infos.isBanned)
				setUsername('');
			}
		})
		.catch(error => {
			console.error('Error:', error);
		});
	}

	const handleLogout = () => {
		if (!token)
			return;
		
		fetch(process.env.REACT_APP_BACKEND_URL + '/logout', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ 
				token:getToken(), 
			})
		})
		.then(response => response.json())
		.then(data => {
			setMessage({text:data.message,color:'green'});
			setTimeout(() => {
				setToken('');
				navigate('/');
				setMessage({text:"",color:""});
			}, 3000);
		})
		.catch(error => console.error('Erreur lors de la déconnexion:', error));
	};
	
	
return (
	<>
		<div className="grid-container">
			<div className="left-content grid-item">
				<div className="login_panel">
					<div className='login_panel_title'>
						{!userInfos || !token ? (
							<h3>Pannel utilisateur</h3>
						) :
							(
								<h3>Bienvenue {userInfos.login}</h3>
							)}
					</div>
					<div className='message_error'>
						<Error msg={{ text: message.text, color: message.color }} />
					</div>
					{token.length > 0 ? (
						<div className="login_panel_content">
							<Link to="/account" className="loginpanel">Mon compte</Link>
							<Link to="/vote" className="loginpanel">Voter</Link>
							<button onClick={handleLogout} className="loginpanel">Déconnexion</button>
						</div>
					) :
						(
							<form onSubmit={handleLogin}>
								<div className="login_panel_content">
									<div className='username'>
										<input type="text" id="username" name="_username" placeholder="Nom de compte" value={username} onChange={(e) => setUsername(e.target.value)} />
									</div>

									<div className='password'>
										<input type="password" id="password" name="_password" placeholder="Mot de passe" value={password} onChange={(e) => setPassword(e.target.value)} />
									</div>

									<div className='keep_login'>
										<input type="checkbox" id="keep_login" name="_keep_login" checked={keepLogin} onChange={e => setKeepLogin(e.target.checked)} />
										<label htmlFor="_keep_login">Maintenir la connexion</label>
									</div>

									<div className='login'>
										<input className="subtitle_panel" type="submit" id='submit' value='Connexion' />
									</div>
								</div>
							</form>
						)}

				</div>

				<div className="lost_id">
					<div className="lost_id_link">
						<p><Link to="/lostuser">Nom de compte oublié ?</Link></p>
						<p><Link to="/lostpwd">Mot de passe oublié ?</Link></p>
					</div>
				</div>

				<div className="discord">
					<Link to="https://discord.gg/BRyCdfKtuD"><img src={discord_img} alt='Discord' /></Link>
				</div>
				<ClassementJoueur />
			</div>
		</div>
	</>
);
}

export default LeftSide;
