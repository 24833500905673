import React, {useEffect, useState} from 'react';
import '../styles/Accueil.css';
import { Link } from 'react-router-dom';
import banner_maj from '../assets/center/news/banner_maj.png';
import banner_annonce from '../assets/center/news/banner_annonce.png';



function Accueil() {
  const [news, setNews] = useState([]);
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/get-news', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      setNews(data.news);
    })
    .catch((error) => {
      console.error('Error:', error);
    }
    );
  }, []);

  const bannerImages = {
    maj: banner_maj,
    annonce: banner_annonce,
  };
  
  const calculateTimeLeft = () => {
    const difference = +new Date("2024-07-06T18:00:00") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            mois: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
            jours: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
            heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            secondes: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

useEffect(() => {
    const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
});


  return (
    <>
    <div className="middle-content grid-item">

      <div className='serv_actu'>

        <div className='actuhead'>
          <h2>Actualités et mises à jour du serveur</h2>
          <hr className='sep'></hr>
        </div>

        <div className='actu_content'>
            {news.map((item, index) => (
              <div key={index} className='actu'>
                <img src={bannerImages[item.type]} alt={item.type}/>
                <p className='p-actu'>{item.content}</p>
                <p className='sign_content-p'>Le {new Date(item.date).toLocaleDateString('fr-FR')} - {item.autor}</p>
                <hr className='sep'></hr>
              </div>
              ))
            }
          </div>
          {/*Ouverture dans {timeLeft.mois} mois, {timeLeft.jours} jours, {timeLeft.heures}H,
		  {timeLeft.minutes}mn, {timeLeft.secondes}s*/}
      </div>
    </div>
    </>
  );
}

export default Accueil;
