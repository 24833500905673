import "../styles/Footer.css";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <p className="copyright"><Link to='/'>Zoria2.fr - All right reserved &copy; {new Date().getFullYear()}</Link></p>
        </footer>
    );
}
export default Footer