import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';	
function TokenVerifier({ children }) {
	const { token, setToken } = useAuth();
	const location = useLocation();

	useEffect(() => {
		const verifyToken = async () => {
		  const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/verify-token', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({ token }),
		  });
	
		  const data = await response.json();
	
		  if (!data.success) {
			setToken('');
		  }
		};
		
		if (token) {
		  verifyToken();
		}
	  }, [token, setToken, location.pathname]);

	  return <>{children}</>;
	}

export default TokenVerifier;