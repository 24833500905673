import "../styles/CreateItem.css";
import { Link } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import Error from './Error';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import utf8 from 'utf8';

function CreateItem() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();
    const { setToken, getToken,userInfos } = useAuth();
    const [isLoaded, setIsLoaded] = useState(false);
	const token = getToken();
    const [pseudo, setPseudo] = useState('');
    const [itemVnum, setItemVnum] = useState('');
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [bonus1, setbonus1] = useState('0');
    const [bonus2, setbonus2] = useState('0');
    const [bonus3, setbonus3] = useState('0');
    const [bonus4, setbonus4] = useState('0');
    const [bonus5, setbonus5] = useState('0');
    const [bonus6, setbonus6] = useState('0');
    const [bonus7, setbonus7] = useState('0');
    const [value_bonus1, setValueBonus1] = useState('0');
    const [value_bonus2, setValueBonus2] = useState('0');
    const [value_bonus3, setValueBonus3] = useState('0');
    const [value_bonus4, setValueBonus4] = useState('0');
    const [value_bonus5, setValueBonus5] = useState('0');
    const [value_bonus6, setValueBonus6] = useState('0');
    const [value_bonus7, setValueBonus7] = useState('0');
    const [keepItem, setKeepItem] = useState(false);
    const [count, setItemCount] = useState('1');
    const [stone1, setStone1] = useState('0');
    const [stone2, setStone2] = useState('0');
    const [stone3, setStone3] = useState('0');
    const [stones, setStones] = useState([]);

    const bonus={0:"Aucun",1:"PV max.",2:"PM max.",3:"Vitalité",4:"Intelligence",5:"Force",6:"Dextérité",7:"Vitesse d'attaque",8:"Vitesse de déplacement",9:"Vitesse du sort",10:"Régénération des PV",11:"Régénération des PM",12:"Chance d'empoisonner",13:"Chance d'assomer",14:"Chance de ralentissement",15:"Chance de coup critique",16:"Chances de coup percant",17:"Bonus contre les demi-humains",18:"Bonus contre les Animaux",19:"Bonus contre les Orcs",
    20:"Bonus contre les Mystiques",21:"Bonus contre les Mort-vivants",22:"Bonus contre le Mal",23:"Drain de PV",24:"Drain de PM",25:"Chance de drain de PM",26:"Récupération de PM",27:"Chance de bloquer un coup au Corps-à-corps",28:"Chance d'éviter les flèches",29:"Défense contre l'épée",30:"Défense contre l'épée à deux-mains",31:"Défense contre la dague",
    32:"Défense contre le Gong",33:"Défense contre l'éventail",34:"Résistance aux flèches",35:"Résistance au feu",36:"Résistance à la foudre",37:"Résistance à la Magie",
    38:"Résistance au Vent",39:"Renvoi d'attaque au Corps-à-corps",40:"Détournement d'une malédiction",41:"Résistance contre le poison",42:"Restauration des PM",43:"Bonus EXP",44:"Double de Yangs",45:"Double d'objets",
    47:"Restauration des PV",48:"Défense contre l'étourdissement",49:"Immunisé contre le ralentissement",53:"Valeur d'attaque",54:"Défense",58:"Max. Endurance",59:"Bonus contre les Guerriers",60:"Bonus contre les Ninjas",61:"Bonus contre les Suras",62:"Bonus contre les Chamans",63:"Bonus contre les Monstres",71:"Dégâts de compétence",72:"Dégâts moyen",73:"Résistance contre les dégâts de compétences",74:"Résistance aux Dégâts Moyens",78:"Chance de parer une attaque Guerrier",79:"Chance de parer une attaque Ninja",80:"Chance de parer une attaque Sura",81:"Chance de parer une attaque Chaman",92:"Résistance aux monstres",93:"Réduction de défense à l'épée",94:"Réduction de demi-humains",95:"Réduction de résistance magie",96:"Réduction de défense à l'épée deux mains",97:"Réduction de défense à la dague",98:"Réduction de défense à l'arc",99:"Réduction de défense au gong",100:"Réduction de défense à l'éventail",132:"Dégâts des coups critiques",}
    const bonusList = Object.entries(bonus).map(([key, value]) => ({
        valeur: key,
        nom: value
    }));
    

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token,navigate]);

    useEffect(() => {
        if (token) {
            fetch(process.env.REACT_APP_BACKEND_URL + '/isa', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: token })
            })
            .then(response => response.json())
            .then(data => {
                if (!data.success) {
                    navigate('/');
                }
                setIsLoaded(true);
            });
        }
    }, [token,navigate]);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/itemvnum', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        setItems(data.items); 
        setStones([{ vnum: 0, locale_name: "Aucune" }, ...data.stones]);
        setFilteredItems(data.items);
      }
    })
    .catch(error => {
      setMessage({ text: 'Erreur lors de la communication avec le serveur. ' + error, color: 'red' });
    });
  }, []); 

  useEffect(() => {
    const filtered = items.filter(item =>
      item.locale_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm, items]);


    useEffect(() => {
        if (isLoaded) {
            const selects_bonus = document.querySelectorAll('.select_bonus');
            selects_bonus.forEach((select) => {
                select.innerHTML = '';
                bonusList.forEach((bonus) => {
                    const option = document.createElement('option');
                    option.value = bonus.valeur;
                    option.text = bonus.nom;
                    select.appendChild(option);
                });
            });
        }
    }, [isLoaded]);
    
    


    const handleGiveItem = (pseudo) => async (e) => {
        e.preventDefault();
        console.log(count)
        if (!pseudo) {
            setMessage({text:'Veuillez saisir un pseudo',color:'red'});
            setTimeout(() => {
                    setMessage({text:'',color:''});
            }, 3000);

            return;
        }

   
        
        if (!itemVnum) {
            setMessage({text:'Veuillez choisir un item',color:'red'});
            setTimeout(() => {
                setMessage({text:'',color:''});
            }, 3000);
            return;
        }

        console.log([pseudo,token,itemVnum,bonus1,value_bonus1,bonus2,value_bonus2,bonus3,value_bonus3,bonus4,value_bonus4,bonus5,value_bonus5,bonus6,value_bonus6,bonus7,value_bonus7]);
        console.log(keepItem)
        fetch(process.env.REACT_APP_BACKEND_URL + '/createitem', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pseudo: pseudo,
                token : token,
                itemVnum: parseInt(itemVnum),
                bonus1: parseInt(bonus1),
                value_bonus1: parseInt(value_bonus1),
                bonus2: parseInt(bonus2),
                value_bonus2: parseInt(value_bonus2),
                bonus3: parseInt(bonus3),
                value_bonus3: parseInt(value_bonus3),
                bonus4: parseInt(bonus4),
                value_bonus4: parseInt(value_bonus4),
                bonus5: parseInt(bonus5),
                value_bonus5: parseInt(value_bonus5),
                bonus6: parseInt(bonus6),
                value_bonus6: parseInt(value_bonus6),
                bonus7: parseInt(bonus7),
                value_bonus7: parseInt(value_bonus7),
                stone1: parseInt(stone1),
                stone2: parseInt(stone2),
                stone3: parseInt(stone3),
                item_count: parseInt(count),
            })
        })
        .then(response => response.json())
        .then(data => {
            setMessage({text: data.message, color: data.success ? 'green' : 'red'});
            setTimeout(() => {
                setMessage({text: '', color: ''});
                if (!keepItem) {
                setSearchTerm('');
                setPseudo('');
                setItemVnum('0');
                setbonus1('0');
                setbonus2('0');
                setbonus3('0');
                setbonus4('0');
                setbonus5('0');
                setbonus6('0');
                setbonus7('0');
                setValueBonus1('0');
                setValueBonus2('0');
                setValueBonus3('0');
                setValueBonus4('0');
                setValueBonus5('0');
                setValueBonus6('0');
                setValueBonus7('0');
                }
            }, 3000);
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });

    }

    return (
        <>
        
        <div className="create_item">
            <div className="create_item_head">
                <h2>Créer un item</h2>
                <hr className="sep"/>
            </div>
            {isLoaded && items && stones && filteredItems ? (
                <>
                <div className="create_item_pannel">
                    <div className='message_error'>
                        <Error msg={{text:message.text,color:message.color}} />
                    </div>
                    <input type="text" placeholder="Pseudo" className="pseudo" value={pseudo} onChange={(e) => setPseudo(e.target.value)}/>
                    <input
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        className="select_itemvnum"
                        value={itemVnum}
                        onChange={(e) => setItemVnum(e.target.value)}
                        required={true}
                    >
                        {filteredItems.map((item) => (
                        <option key={item.vnum} value={item.vnum}>{item.locale_name}</option>
                        ))}
                    </select>

                    <hr className="sep"/>

                    <div className="create_item_pannel_data">
                        <select value={bonus1} onChange={(e) => setbonus1(e.target.value)} required="true" placeholder="Bonus 1" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus1} onChange={(e) => setValueBonus1(e.target.value)}/>
                    </div>
                     <div className="create_item_pannel_data">
                        <select value={bonus2} onChange={(e) => setbonus2(e.target.value)} required="true" placeholder="Bonus 2" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus2} onChange={(e) => setValueBonus2(e.target.value)}/>
                    </div>
                    <div className="create_item_pannel_data">
                        <select value={bonus3} onChange={(e) => setbonus3(e.target.value)} required="true" placeholder="Bonus 3" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus3} onChange={(e) => setValueBonus3(e.target.value)}/>
                    </div>
                    <div className="create_item_pannel_data">
                        <select value={bonus4} onChange={(e) => setbonus4(e.target.value)} required="true" placeholder="Bonus 4" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus4} onChange={(e) => setValueBonus4(e.target.value)}/>
                    </div>
                    <div className="create_item_pannel_data">
                        <select value={bonus5} onChange={(e) => setbonus5(e.target.value)} required="true" placeholder="Bonus 5" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus5} onChange={(e) => setValueBonus5(e.target.value)}/>
                    </div>
                    <hr className="sep"/>
                    <div className="create_item_pannel_data">
                        <select value={bonus6} onChange={(e) => setbonus6(e.target.value)} required="true" placeholder="Bonus 6" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus6} onChange={(e) => setValueBonus6(e.target.value)}/>
                    </div>
                    <div className="create_item_pannel_data">
                        <select value={bonus7} onChange={(e) => setbonus7(e.target.value)} required="true" placeholder="Bonus 7" className="select_bonus alignleft_bonus"></select>
                        <input type="text" className="bonus_value alignright_bonus" required="true" value={value_bonus7} onChange={(e) => setValueBonus7(e.target.value)}/>
                    </div>

                    <hr className="sep"/>
                    <select className="select_stone" value={stone1} onChange={(e) => setStone1(e.target.value)}required={true}>
                        {stones.map((item) => (
                        <option key={item.vnum} value={item.vnum}>{item.locale_name}</option>
                        ))}
                    </select>
                    <select className="select_stone" value={stone2} onChange={(e) => setStone2(e.target.value)}required={true}>
                        {stones.map((item) => (
                        <option key={item.vnum} value={item.vnum}>{item.locale_name}</option>
                        ))}
                    </select>
                    <select className="select_stone" value={stone3} onChange={(e) => setStone3(e.target.value)}required={true}>
                        {stones.map((item) => (
                        <option key={item.vnum} value={item.vnum}>{item.locale_name}</option>
                        ))}
                    </select>
                    <hr className="sep"/>
                    <input type="number" placeholder="Nombre" className="item_count" value={count} onChange={(e) => setItemCount(e.target.value)}/>

                    <div className='keep_item'>
                        <input type="checkbox" id="keep_item" name="_keep_item" checked={keepItem} onChange={e => setKeepItem(e.target.checked)} />
                        <label htmlFor="_keep_item">Garder ces paramètres</label>
					</div>

                    <hr className="sep"/>
                    <button className="create_item_button" onClick={handleGiveItem(pseudo)}>Créer l'item</button>
                </div>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
        </>
    );
}
export default CreateItem;