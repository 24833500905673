import React, {useState} from 'react';
import '../styles/ClassementGuilde.css';
import chunjo_icon from "../assets/left/rank/chunjo_icon.png";
import jinno_icon from "../assets/left/rank/jinno_icon.png";
import shinsoo_icon from "../assets/left/rank/shinsoo_icon.png";
import discord_img from "../assets/left/discord_img.png";
import { Link } from 'react-router-dom';
import Error from './Error';
import {useNavigate} from 'react-router-dom';
import { useAuth } from './AuthContext';




function ClassementGuilde() {
const {rightRanking} = useAuth();
    
return (
	<>
		<div className="right_guild_ranking">
			<div className="right_guild_ranking_he">
				<h2>Classement Guildes</h2>
			</div>
			<table>
				<thead>
					<tr>
						<th style={{ width: '38px' }}>#</th>
						<th style={{ width: '119px' }}>Nom</th>
						<th style={{ width: '87px' }}>Empire</th>
						<th style={{ width: '120px' }}>Points</th>
					</tr>
				</thead>
				<tbody>
					{rightRanking.map(guild => (
						<tr className="rank_catplayer" key={guild.rank}>
							<td className="right_guild_rank_no">{guild.rank}.</td>
							<td className="right_guild_rank_name">{guild.name}</td>
							<td className="right_guild_rank_emp">
								{guild.empire === 1 ? (
									<img src={shinsoo_icon} alt="emp_flag" />
								) : guild.empire === 2 ? (
									<img src={chunjo_icon} alt="emp_flag" />
								) : (
									<img src={jinno_icon} alt="emp_flag" />
								)}
							</td>
							<td className="right_guild_rank_points">{guild.ladder_point}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
		<div className="show_all_rank_left">
			<Link to='ranking/guild'>Afficher le classement complet</Link>
		</div>
	</>
);
}

export default ClassementGuilde;
