import "../styles/LostPwd.css";
import { Link,useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Error from './Error';


function LostPwd() {
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();

    const handleLostPassword = async (e) => {
        e.preventDefault();

        if (!username) {
            setMessage({text:'Veuillez saisir votre identifiant',color:'red'});
            return;
        }

        fetch(process.env.REACT_APP_BACKEND_URL + '/lostpwd', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username
            })
        })
        .then(response => response.json())
        .then(data => {
            setMessage({text: data.message, color: data.success ? 'green' : 'red'});
        
            setTimeout(() => {
                setMessage({text: '', color: ''});
                setUsername('');
            }, 3000);
    
            if (data.success) {
                setTimeout(() => {
                    navigate('/');
                }, 3000);
            }
        })
        .catch(error => {
            setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
        });

    }


    return (
    <div className="lostpwd">
        <div className="lostpwd_head">
            <h2>Mot de passe oublié ?</h2>
            <hr className="sep"/>
        </div>
        
        <div className="lostpwd_pannel">
            <form onSubmit={handleLostPassword}>
            <input type="text" id="name" className="user_name" placeholder="Votre identifiant" value={username} onChange={(e) => setUsername(e.target.value)}/>
                <button type="submit" class="change_password">Changer le mot de passe</button>
            </form>
        </div>

        <div className='message_error'>
            <Error msg={{text:message.text,color:message.color}} />
        </div>

    </div>
    );
}
export default LostPwd