import "../styles/Compte.css";
import { Link } from 'react-router-dom';
import React, {useEffect, useState } from 'react';
import Error from './Error';
import { useNavigate } from "react-router-dom";
import { useAuth } from './AuthContext';


function Compte() {
    const [message, setMessage] = useState({ text: '', color: '' });
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [new_password_confirm, setNewPasswordConfirm] = useState('');
    const { setToken, getToken,userInfos, isAdmin, isBanned,fetchUserInfos } = useAuth();
	const token = getToken();

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
        else {
            fetchUserInfos();
        }
    }, [token,navigate]);


    const handleChangePassword = (e) => {
        e.preventDefault();
        if (!password || !new_password || !new_password_confirm) {
            setMessage({text:"Veuillez remplir tous les champs.",color:"red"});
            setTimeout(() => {
                setMessage({text:"",color:""});
                setPassword('');
                setNewPassword('');
                setNewPasswordConfirm('');
            }, 3000);
        }

        if (password && new_password && new_password_confirm) {
            fetch(process.env.REACT_APP_BACKEND_URL + '/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body:
                 JSON.stringify({ 
                    password:password, 
                    new_password:new_password,
                    new_password_confirm:new_password_confirm,
                    token:getToken()
                })
            })
            .then(response => response.json())
            .then(data => {
                setMessage({text:data.message,color:data.success ? 'green' : 'red'});
                setTimeout(() => {
                    setMessage({text:"",color:""});
                    setPassword('');
                    setNewPassword('');
                    setNewPasswordConfirm('');
                }, 3000);
                if (data.success) {
                    setTimeout(() => {
                        setToken('');
                        navigate('/');
                    }, 3000);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }

    return (
        <div className="account">
            <div className="account_head">
                <h2>Mon compte</h2>
                <hr className="sep"/>
            </div>
            <div className="account_pannel">
                {!isBanned ? 
                    (
                    <>
                        <div className="account_pannel_data">
                            <h2 className="alignleft">Nom de compte :</h2>
                            <h2 className="alignright">{userInfos.login}</h2>
                        </div>
                        <hr className="sep"/>
                        <div className="account_pannel_data">
                            <h2 className="alignleft">Email :</h2>
                            <h2 className="alignright">{userInfos.email}</h2>
                        </div>
                        <hr className="sep"/>
                        <div className="account_pannel_data">
                            <h2 className="alignleft">ZoriaCoins :</h2>
                            <h2 className="alignright">{userInfos.cash}</h2>
                        </div>
                        <hr className="sep"/>
                        <div className="account_pannel_data">
                            <h2 className="alignleft">Nombre de votes :</h2>
                            <h2 className="alignright">{userInfos.vote}</h2>
                        </div>
                        <hr className="sep"/>
                        <div className="account_head">
                            <h2>Rechargement</h2>
                            <hr className="sep"/> 
                        </div>

                        <div className="rechargement_content">
                            <div className="rechargement">
                                <Link to="/rechargement" className="paypal_button">Paypal</Link>
                            </div>
                        </div>

                        <div className="account_head">
                            <hr className="sep"/>
                            <h2>Changement de mot de passe</h2>
                            <hr className="sep"/> 
                        </div>

                        <div className="password_reset">
                            <form className="reset_mdp" onSubmit={handleChangePassword}>
                                <div className='message_error'>
                                    <Error msg={{text:message.text,color:message.color}} />
                                </div>
                                <input type="password" id="old_password" name="_password" placeholder="Ancien mot de passe" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                <input type="password" id="new_password" name="new_password" pattern=".{4,16}" placeholder="Nouveau mot de passe" value={new_password} onChange={(e) => setNewPassword(e.target.value)}/>
                                <input type="password" id="new_password_confirm" name="new_password_confirm" pattern=".{4,16}" placeholder="Confirmer le nouveau mot de passe" value={new_password_confirm} onChange={(e) => setNewPasswordConfirm(e.target.value)}/>
                                <button type="submit" className="change_password">Changer le mot de passe</button>
                            </form>
                        </div>

                        <hr className="sep"/>

                        <div className="admin-pann">
                            {isAdmin && <Link to="/admin" className="admin_button">Administration</Link>}
                        </div>
                    </>
                    ) : (
					                    <>
                    <div className="account_pannel_data">
                        <h2 className="alignleft">Statut :</h2>
                        <h2 className="alignright">Banni</h2>
                    </div>
                    <div className="account_pannel_data">
                        <h2 className="alignleft">Raison :</h2>
                        <h2 className="alignright">{isBanned.reason}</h2>
                    </div>
                    <div className="account_pannel_data">
                        <h2 className="alignleft">Durée :</h2>
                        <h2 className="alignright">
                        {new Date(isBanned.duree).toLocaleString('fr-FR', {
                            year: 'numeric', month: '2-digit', day: '2-digit',
                            hour: '2-digit', minute: '2-digit', second: '2-digit',
                            hour12: false
                        }).replace(/T/, ' ').replace(/\u200E/g, '').replace(/,/g, '')}
                        </h2>
                    </div>
                    </>
                    )}
            </div>

        </div>
    );
}export default Compte
