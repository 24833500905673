import '../styles/Inscription.css';
import {useState, createRef, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import Error from './Error';
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from './AuthContext';






function Inscription() {
const [message, setMessage] = useState({ text: '', color: '' });
const [username , setUsername] = useState('');
const [password , setPassword] = useState('');
const [password_confirm , setPassword_confirm] = useState('');
const [mail , setMail] = useState('');
const [code , setCode] = useState('');
const [type, setType] = useState('password');
const [type2, setType2] = useState('password');
const [icon, setIcon] = useState(eyeOff);
const [icon2, setIcon2] = useState(eyeOff);
const recaptchaRef = createRef();
const [captchaValue, setCaptchaValue] = useState('');
const navigate = useNavigate();
const { canRegister } = useAuth(); 

const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
}

const handleToggle2 = () => {
    if (type2==='password'){
       setIcon2(eye);
       setType2('text')
    } else {
       setIcon2(eyeOff)
       setType2('password')
    }
}



const handleInscription = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
        alert("Veuillez résoudre le CAPTCHA.");
        return;
    }

    const captchaToken = recaptchaRef.current.getValue();

    fetch(process.env.REACT_APP_BACKEND_URL + '/inscription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            username: username,
            password: password,
            mail: mail,
            code: code,
            captchaToken: captchaToken
        })
    })
    .then(response => response.json())
    .then(data => {
        setMessage({text: data.message, color: data.success ? 'green' : 'red'});
        recaptchaRef.current.reset();
        setCaptchaValue('');
    
        setTimeout(() => {
            setMessage({text: '', color: ''});
            setUsername('');
            setPassword('');
            setPassword_confirm('');
            setMail('');
            setCode('');
        }, 3000);

        if (data.success) {
            setTimeout(() => {
                navigate('/');
            }, 3000);
        }
    })
    .catch(error => {
        setMessage({text: 'Erreur lors de la communication avec le serveur.' + error, color: 'red'});
    });
};



  return (
    <div className="inscription">
        <div className="inscription_head">
            <h2>Inscription</h2>
            <hr className='sep'></hr>
        </div>
        <div className='message_error'>
            <Error msg={{text:message.text,color:message.color}} />
        </div>
        <div className="inscription_pannel">
            {canRegister ? (
            <form onSubmit={handleInscription}>
                <input type="text" id="name" className="user_name" placeholder="Votre identifiant" value={username} onChange={(e) => setUsername(e.target.value)} pattern="[A-Za-z0-9]{4,16}" title="L'identifiant doit comporter entre 4 et 16 caractères alphanumériques sans accents ni caractères spéciaux." />
                <p className='small'>Pour la sécurité de votre compte, veillez à toujours utiliser des identifiants différents que ceux des autres serveurs.</p>
                <div className="password-container">
                    <input type={type} id="pass_word" className="pass_word" placeholder="Votre mot de passe (4-16 caractères)" value={password} onChange={(e) => setPassword(e.target.value)} pattern=".{4,16}" title="Le mot de passe doit comporter entre 4 et 16 caractères." />
                    <span onClick={handleToggle} className="password-icon">
                        <Icon icon={icon} size={15}/>
                    </span>
                </div>

                <div className="password-container">
                    <input type={type2} id="pass_word_confirm" className="pass_word_confirm" placeholder="Veuillez répéter votre mot de passe" pattern=".{4,16}" title="Le mot de passe doit comporter entre 4 et 16 caractères." value={password_confirm} onChange={(e) => setPassword_confirm(e.target.value)}/>
                    <span onClick={handleToggle2} className="password-icon">
                        <Icon icon={icon2} size={15}/>
                    </span>
                </div>
                <input type="text" id="code_delete" className="code_delete" placeholder="Code de suppression (7 chiffres)" value={code} onChange={(e) => setCode(e.target.value)} pattern="\d{7}" title="Le code doit être composé de 7 chiffres." />
                <input type="email" id="mail_adress" className="mail_adress" placeholder="Votre adresse mail" value={mail} onChange={(e) => setMail(e.target.value)} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" title="Veuillez entrer une adresse email valide." />
                <p className='small'>Veillez à entrer une adresse email valide, elle pourra être utilisée en cas de perte du mot de passe.</p>
                <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdWcIIpAAAAAH9lyO2JBuIzewQO2Owf1OqWXWoK"
                onChange={setCaptchaValue}
                />
                <div className="inscription_btn">
                    <input className="inscription_btn" disabled={!captchaValue} type="submit" id="submit" value="S'INSCRIRE"/>
                </div>
            </form>
            ) : (
                <span>Les inscriptions sont actuellement fermées.</span>
            
            )}
        </div>
    </div>
  );
}

export default Inscription;
