import "../styles/Telechargement.css";
import { Link } from 'react-router-dom';

function Telechargement() {
    return (
        <div className="download">
            <div className="download-head">
                <h2>Téléchargement</h2>
                <hr className='sep'></hr>
            </div>
            <div className="download-pannel">
                <Link to="https://mega.nz/file/7UEGkC5A#wG3pjZyY9n96l-N5a2r0itKoIuxgVTWaPVJmzRL_Lno" className="download-link">MEGA</Link>
            </div>
            <hr className="sep"/>
            <div className="download-pannel">
                <Link to="https://www.mediafire.com/file/bk1n2ilg53mz57a/Zoria2+Client+1.0.zip/file" className="download-link">MediaFire</Link>
            </div>
        </div>
    );
}
export default Telechargement